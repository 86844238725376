export interface UserInfo {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  tenant_id: string;
  tenant_name: string;
  open_id?: any;
  user_name: string;
  real_name: string;
  client_id: string;
  role_name: string;
  license: string;
  user_id: string;
  role_id: string;
  nick_name: string;
  union_id?: any;
  oauth_id: string;
  account: string;
  jti: string;
}
export interface TenantInfo {
  id: string;
  createUser: string;
  createTime: string;
  updateUser: string;
  updateTime: string;
  status: number;
  isDeleted: number;
  tenantId: string;
  name: string;
  contacts: string;
  phone: string;
  userId: string;
  email?: any;
  address: string;
  logo: string;
  description?: any;
  moduleIdList?: any;
  parentId: string;
  children: any[];
  hasChildren?: any;
  tenantName: string;
}

export const getDefaultUserInfo = (): UserInfo => {
  return {
    access_token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnRfaWQiOiI3NDYxNzYxODg4NjgiLCJ0ZW5hbnRfbmFtZSI6IueglOWPkeS4reW_gyIsIm9wZW5faWQiOm51bGwsInVzZXJfbmFtZSI6IjEzMzMzMzMzMzMzIiwicmVhbF9uYW1lIjoi6LaF57qn566h55CG5ZGYIiwiYXV0aG9yaXRpZXMiOlsi566h55CG5ZGY6KeS6ImyIl0sImNsaWVudF9pZCI6InNpbnpldGVjaC1lcnAiLCJyb2xlX25hbWUiOiLnrqHnkIblkZjop5LoibIiLCJsaWNlbnNlIjoicG93ZXJlZCBieSBzaW56ZXRlY2giLCJ1c2VyX2lkIjoiMSIsInJvbGVfaWQiOiI5OTk5OTk5OTksOTk5OTk5OTk5LDE1NDE5NzYzNDc0NTI0NDg3NjksOTk5OTk5OTk5LDE1NDE5NzYzNDc0NTI0NDg3NjksMTU0MTk3NjU5NDIyNDMyNDYxMCw5OTk5OTk5OTksMTU0MTk3NjM0NzQ1MjQ0ODc2OSwxNTQxOTc2NTk0MjI0MzI0NjEwLDk5OTk5OTk5OSwxNTQxOTc2MzQ3NDUyNDQ4NzY5LDE1NDE5NzY1OTQyMjQzMjQ2MTAsOTk5OTk5OTk5LDE1NDE5NzYzNDc0NTI0NDg3NjksMTU0MTk3NjU5NDIyNDMyNDYxMCw5OTk5OTk5OTksMTU0MTk3NjM0NzQ1MjQ0ODc2OSwxNTQxOTc2NTk0MjI0MzI0NjEwLDk5OTk5OTk5OSwxNTQxOTc2MzQ3NDUyNDQ4NzY5LDE1NDE5NzY1OTQyMjQzMjQ2MTAsOTk5OTk5OTk5LDE1NDE5NzYzNDc0NTI0NDg3NjksMTU0MTk3NjU5NDIyNDMyNDYxMCw5OTk5OTk5OTksMTU0MTk3NjM0NzQ1MjQ0ODc2OSwxNTQxOTc2NTk0MjI0MzI0NjEwLDk5OTk5OTk5OSwxNTQxOTc2MzQ3NDUyNDQ4NzY5LDE1NDE5NzY1OTQyMjQzMjQ2MTAsOTk5OTk5OTk5LDE1NDE5NzYzNDc0NTI0NDg3NjksMTU0MTk3NjU5NDIyNDMyNDYxMCw5OTk5OTk5OTksMTU0MTk3NjM0NzQ1MjQ0ODc2OSwxNTQxOTc2NTk0MjI0MzI0NjEwIiwic2NvcGUiOlsiYWxsIl0sIm5pY2tfbmFtZSI6Iui2hee6p-euoeeQhuWRmCIsInVuaW9uX2lkIjpudWxsLCJvYXV0aF9pZCI6IiIsImV4cCI6MTY3NDY5NTIzNSwianRpIjoiNTRjNDc5YjctNjdmYS00MjA4LWIzM2MtMGE2ZjAxNzJmMTY5IiwiYWNjb3VudCI6IjEzMzMzMzMzMzMzIn0.sQxKIeVHLTA143gw4HjNQ-2uJtKm9bV4T58XT59gQSg",
    token_type: "bearer",
    refresh_token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZW5hbnRfaWQiOiI3NDYxNzYxODg4NjgiLCJ0ZW5hbnRfbmFtZSI6IueglOWPkeS4reW_gyIsIm9wZW5faWQiOm51bGwsInVzZXJfbmFtZSI6IjEzMzMzMzMzMzMzIiwicmVhbF9uYW1lIjoi6LaF57qn566h55CG5ZGYIiwiYXV0aG9yaXRpZXMiOlsi566h55CG5ZGY6KeS6ImyIl0sImNsaWVudF9pZCI6InNpbnpldGVjaC1lcnAiLCJyb2xlX25hbWUiOiLnrqHnkIblkZjop5LoibIiLCJsaWNlbnNlIjoicG93ZXJlZCBieSBzaW56ZXRlY2giLCJ1c2VyX2lkIjoiMSIsInJvbGVfaWQiOiI5OTk5OTk5OTksOTk5OTk5OTk5LDE1NDE5NzYzNDc0NTI0NDg3NjksOTk5OTk5OTk5LDE1NDE5NzYzNDc0NTI0NDg3NjksMTU0MTk3NjU5NDIyNDMyNDYxMCw5OTk5OTk5OTksMTU0MTk3NjM0NzQ1MjQ0ODc2OSwxNTQxOTc2NTk0MjI0MzI0NjEwLDk5OTk5OTk5OSwxNTQxOTc2MzQ3NDUyNDQ4NzY5LDE1NDE5NzY1OTQyMjQzMjQ2MTAsOTk5OTk5OTk5LDE1NDE5NzYzNDc0NTI0NDg3NjksMTU0MTk3NjU5NDIyNDMyNDYxMCw5OTk5OTk5OTksMTU0MTk3NjM0NzQ1MjQ0ODc2OSwxNTQxOTc2NTk0MjI0MzI0NjEwLDk5OTk5OTk5OSwxNTQxOTc2MzQ3NDUyNDQ4NzY5LDE1NDE5NzY1OTQyMjQzMjQ2MTAsOTk5OTk5OTk5LDE1NDE5NzYzNDc0NTI0NDg3NjksMTU0MTk3NjU5NDIyNDMyNDYxMCw5OTk5OTk5OTksMTU0MTk3NjM0NzQ1MjQ0ODc2OSwxNTQxOTc2NTk0MjI0MzI0NjEwLDk5OTk5OTk5OSwxNTQxOTc2MzQ3NDUyNDQ4NzY5LDE1NDE5NzY1OTQyMjQzMjQ2MTAsOTk5OTk5OTk5LDE1NDE5NzYzNDc0NTI0NDg3NjksMTU0MTk3NjU5NDIyNDMyNDYxMCw5OTk5OTk5OTksMTU0MTk3NjM0NzQ1MjQ0ODc2OSwxNTQxOTc2NTk0MjI0MzI0NjEwIiwic2NvcGUiOlsiYWxsIl0sIm5pY2tfbmFtZSI6Iui2hee6p-euoeeQhuWRmCIsImF0aSI6IjU0YzQ3OWI3LTY3ZmEtNDIwOC1iMzNjLTBhNmYwMTcyZjE2OSIsInVuaW9uX2lkIjpudWxsLCJvYXV0aF9pZCI6IiIsImV4cCI6MTY3NDY5NTIzNSwianRpIjoiNTI4NTA3MWEtODZmMC00MjgwLWIwMGYtOWE5NTFiYmM5YTQyIiwiYWNjb3VudCI6IjEzMzMzMzMzMzMzIn0.yZR3NMkjIVPm1LudrXmhlBX6h_waI8ROq_4K4_x_pe8",
    expires_in: 2591999,
    scope: "all",
    tenant_id: "746176188868",
    tenant_name: "研发中心",
    open_id: null,
    user_name: "13333333333",
    real_name: "超级管理员",
    client_id: "sinzetech-erp",
    role_name: "管理员角色",
    license: "powered by sinzetech",
    user_id: "1",
    role_id:
      "999999999,999999999,1541976347452448769,999999999,1541976347452448769,1541976594224324610,999999999,1541976347452448769,1541976594224324610,999999999,1541976347452448769,1541976594224324610,999999999,1541976347452448769,1541976594224324610,999999999,1541976347452448769,1541976594224324610,999999999,1541976347452448769,1541976594224324610,999999999,1541976347452448769,1541976594224324610,999999999,1541976347452448769,1541976594224324610,999999999,1541976347452448769,1541976594224324610,999999999,1541976347452448769,1541976594224324610,999999999,1541976347452448769,1541976594224324610",
    nick_name: "超级管理员",
    union_id: null,
    oauth_id: "",
    account: "13333333333",
    jti: "54c479b7-67fa-4208-b33c-0a6f0172f169"
  };
};
export const getDefaultTenantInfo = (): TenantInfo => {
  return {
    id: "1417078861236219906",
    createUser: "777777777",
    createTime: "2021-07-19 19:08:23",
    updateUser: "1408427124569804800",
    updateTime: "2022-11-24 10:01:53",
    status: 1,
    isDeleted: 0,
    tenantId: "253388633853",
    name: "EP测试公司",
    contacts: "管理员",
    phone: "13333333333",
    userId: "1408427124569804800",
    email: null,
    address: "1",
    logo: "",
    description: null,
    moduleIdList: null,
    parentId: "0",
    children: [],
    hasChildren: null,
    tenantName: "EP测试公司"
  };
};
