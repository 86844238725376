import { isQianKun } from "../micro/qiankun";

export const LowcodeGlobalHanlderKey: any = isQianKun ? "lce" : "lowcode";
export const onInitLowcodeGlobalHanlder = () => {
  if (!window[LowcodeGlobalHanlderKey]) {
    window[LowcodeGlobalHanlderKey] = {} as any;
  }
  const userInfo = JSON.parse(sessionStorage.getItem("user") ?? "{}");
  if (userInfo.authorKeys) {
    const componentList = new Set(userInfo.authorKeys);
    window.lce.useAuthor = (key: string) => {
      return componentList.has(key);
    };
    appendLowcodeGlobalHanlder("searchModel2Hash", searchModel2Hash);
    appendLowcodeGlobalHanlder("hash2SearchModel", hash2SearchModel);
  }
};
export const appendLowcodeGlobalHanlder = (key: string, hanlder: any) => {
  if (!window[LowcodeGlobalHanlderKey]) {
    window[LowcodeGlobalHanlderKey] = {} as any;
  }
  window[LowcodeGlobalHanlderKey][key as any] = hanlder;
};

const searchModel2Hash = (model: Recordable) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  model = Object.entries(model).reduce((obj, [k, v]) => {
    if (v !== undefined && v !== null && v !== "") {
      (obj as any)[k] = v;
    }
    return obj;
  }, {});
  const modelStr = encodeURIComponent(new URLSearchParams(Object.entries(model)).toString());
  location.hash = modelStr;
  return modelStr;
};

const hash2SearchModel = (hash?: string) => {
  const str = hash ?? location.hash;
  return Array.from(
    new URLSearchParams(decodeURIComponent(str).slice(str.startsWith("#") ? 1 : 0)).entries()
  ).reduce<Recordable>((o, [k, v]) => {
    // console.log(item, 'item');
    o[k] = v;
    return o;
  }, {});
};
