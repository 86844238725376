import { notification } from "antd";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse
} from "axios";

import qs from "qs";
import { useUserStore } from "@/store/user";

import { config } from "./config";

const { result_code } = config;

// export const PATH_URL = base_url[import.meta.env.VITE_API_BASEPATH];

// 创建axios实例
const service: AxiosInstance = axios.create({
  baseURL: "", // api 的 base_url
  timeout: config.request_timeout // 请求超时时间
});
const userStore = useUserStore();

// request拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (
      config.method === "post" &&
      (config.headers as AxiosRequestHeaders)["Content-Type"] ===
        "application/x-www-form-urlencoded"
    ) {
      config.data = qs.stringify(config.data);
    }
    if (userStore.info?.access_token) {
      config.headers!["Sinzetech-Auth"] = userStore.info?.access_token;
    }
    config.headers!["tenant-id"] = userStore.tenantInfo?.tenantId || ""; // 999999999
    config.headers = {
      ...config.headers
    };
    // get参数编码
    if (config.method === "get" && config.params) {
      let url = config.url as string;
      url += "?";
      const keys = Object.keys(config.params);
      for (const key of keys) {
        if (config.params[key] !== void 0 && config.params[key] !== null) {
          url += `${key}=${encodeURIComponent(config.params[key])}&`;
        }
      }
      url = url.substring(0, url.length - 1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error: AxiosError) => {
    // Do something with request error
    console.error(error); // for debug
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    // if (import.meta.env.DEV && response.config.url?.startsWith("http://yapi.saikul.com")) {
    //   response.data.code = 200;
    // }
    if (response.data.code && response.data.msg) {
      if (response.data.code === result_code) {
        return response.data;
      } else {
        const msg = response?.data?.msg || response?.data?.message;
        // ElMessage.error(response.data.message);
        notification.error({
          message: msg
        });
      }
    }
    return response.data as any;
  },
  (error: AxiosError<any>) => {
    // const gOnError = globalCommonConfig.http.interceptors.onError;
    // if (gOnError) {
    //   if (gOnError(error)) return;
    // }
    const msg = error.response?.data?.msg || error.message;
    console.error("err", msg); // for debug
    notification.error({
      message: msg
    });
    if (error.response?.data.code === 401) {
      useUserStore().onLogOut();
    }
    // ElMessage.error(msg);
    return Promise.reject(error.response?.data ?? error);
  }
);

export { service };
