import React, { PropsWithChildren, useEffect, useRef } from "react";
import ExportStoreList, { ExportDataModalParams } from "@/components/export/storeListnew";
import ImportDataModal, { ImportModalParams } from "@/components/import/readDataModal/importData";
import { appendLowcodeGlobalHanlder } from "@/utils/lowcode";
export const ModalWrapper = ({ children }: PropsWithChildren) => {
  const exportEl = useRef<any>();
  const importEl = useRef<any>();
  const onShowExportModal = (params: ExportDataModalParams) => {
    exportEl.current.onShow(params);
  };
  const onShowImportModal = (params: ImportModalParams) => {
    importEl.current.onShow(params);
  };
  useEffect(() => {
    const adminHooks = {
      onShowExportModal,
      onShowImportModal
    };

    Object.keys(adminHooks).forEach((k) => {
      appendLowcodeGlobalHanlder(k, (adminHooks as any)[k]);
      // (window.lce as any)[k] = (adminHooks as any)[k];
    });

    // setTimeout(() => {
    // exportEl.current.onShow({
    //   columnsKey: () => [{ field: "name", label: "啊啊" }],
    //   searchObj: {},
    //   pageSize: 10,
    //   current: 1,
    //   total: 100,
    //   exportUrl: "aaa",
    //   exportFileName: "hallo"
    // });
    // onShowImportModal({
    //   uploadUrl: "/api/sinzetech-user/user/import",
    //   getTaskQueryUrl(res) {
    //     return `/api/sinzetech-user/user/import/${res}`;
    //   },
    //   getDownloadErrorUrl(res) {
    //     return `/api/sinzetech-user/user/import/${res}/fail`;
    //   },
    //   downTempUrl: "/api/sinzetech-user/user/import/template",
    //   templateName: "批量导入用户"
    // });
    // }, 1000);
  }, []);
  return (
    <div>
      {children}
      <ExportStoreList ref={exportEl} />
      <ImportDataModal ref={importEl} />
    </div>
  );
};
