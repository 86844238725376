/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-string-refs */
import React, { Component } from "react";
import "./importData.scss";
import { message, Modal, notification, Spin } from "antd";
import { useAxios } from "../../../utils/axios/useAxios";
import { sleep } from "@/utils";
const { request } = useAxios();

declare module "react" {
  interface HTMLAttributes<T> {
    webkitdirectory?: string;
    mozdirectory?: string;
    enctype?: string;
  }
  interface FormHTMLAttributes<T> {
    onsubmit?: any;
  }
}

export interface ImportModalParams {
  onSuccess?: (result: { failCount: number; completeCount: number }) => void; //关闭弹窗
  importSuccessData?: () => void; //要回显的数据
  uploadUrl: string; //导入的api路径
  getTaskQueryUrl: (res: any) => string; //导入的api路径
  getDownloadErrorUrl: (res: any) => string; //解析下载错误数据api
  templateName?: string;
  downTempUrl?: string; //下载模板连接
  title?: string; //弹框标题
  multiple?: boolean;
}
interface Parsing {
  // allCount: number,//导入数据总条数
  taskId?: string;
  failCount: number; //导入数据错误条数
  isComplete: boolean; //是否有错误数据
  completeCount: number; //导入正确数据
  failList?: any[];
}
interface State {
  isLoading: boolean;
  isShow: boolean;
  InputFileList: any; //文件存储列表
  selectedFileList: any[]; //已上传要回显的filelist
  currentFileSize: number; //当前上传的进度
  fileSize: number; //上传文件的大小
  parsing: Parsing;
  serialNumber: string; //序列号
  params: ImportModalParams | null;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}
class ImportData extends Component<Props, State> {
  state: State = {
    isLoading: false,
    isShow: false,
    InputFileList: [], //文件存储列表
    selectedFileList: [], //已上传要回显的filelist
    currentFileSize: 0, //当前上传的进度
    fileSize: 0, //上传文件的大小
    serialNumber: "", //序列号
    parsing: {
      // allCount: 0,//导入数据总条数
      failCount: 0, //导入数据错误条数
      isComplete: true, //是否有错误数据
      completeCount: 0, //导入正确数据
      failList: []
    },
    params: null
  };
  timer: NodeJS.Timeout | null = null;
  parsingKey: any; //弹框key
  // UNSAFE_componentWillMount() {}
  // 上传文件change
  onShow = (params: ImportModalParams) => {
    this.setState({
      params,
      isShow: true
    });
  };
  fromChange = () => {
    let { InputFileList } = this.state;
    // if (selectedFileList.length) {
    //     message.error('只能上传一个文件！')
    //     return
    // }
    const selectedFileList = [];
    const str = "xlsx xls xltx xlt xlsm xlsb xltm csv";
    // eslint-disable-next-line react/no-string-refs
    InputFileList = (this.refs.file as any).files;
    for (const value of InputFileList) {
      const first = value.name.lastIndexOf("."); //取到文件名开始到最后一个点的长度
      const namelength = value.name.length; //取到文件名长度
      const temstr = value.name.substring(first + 1, namelength); //截取获得后缀名
      if (str.indexOf(temstr) == -1) {
        this.setState({ InputFileList: [] });
        message.error("请上传正确格式表格！.xlsx .xls");
        return;
      }
      selectedFileList.push(value);
    }
    this.setState({ InputFileList, selectedFileList });
  };
  //导入表格
  uploadFile = async () => {
    const { InputFileList, parsing } = this.state;
    if (!parsing.isComplete) {
      message.error("有文件未处理完成！");
      return;
    }
    if (!InputFileList.length) {
      message.error("请选择上传文件！");
      return;
    }
    const fd = new FormData();
    for (const value of InputFileList) {
      fd.append("file", value);
    }
    this.setState({ isLoading: true });

    try {
      const res = await request<string>({
        url: this.state.params!.uploadUrl,
        method: "post",
        data: fd,
        onUploadProgress: (file) => {
          this.setState({
            fileSize: file.total!, //上传文件的大小
            currentFileSize: file.loaded //当前上传的进度
          });
        },
        headersType: "multipart/form-data"
      });
      const file = document.getElementById("fileExcel") as HTMLInputElement;
      file.value = "";
      this.setState({
        InputFileList: [],
        selectedFileList: []
      });
      let importRes: any;
      for (;;) {
        await sleep(1000);
        importRes = await request({
          url: this.state.params!.getTaskQueryUrl(res.data)
        });
        if (importRes.data.isComplete) {
          break;
        }
      }
      const data = importRes.data as any;
      this.setState({
        parsing: {
          // allCount: 0,//导入数据总条数
          failCount: data.failCount, //导入数据错误条数
          isComplete: true, //是否有错误数据
          completeCount: data.completeCount, //导入正确数据
          taskId: res.data
        }
      });
      this.state.params?.onSuccess && this.state.params?.onSuccess(data);
      this.openNotification();
    } finally {
      this.setState({
        isLoading: false,
        isShow: false
      });
    }
  };
  //下载错误数据
  downErroData = () => {
    this.setState({ isLoading: true });
    request({
      url: `${this.state.params!.getDownloadErrorUrl(this.state.parsing.taskId)}`,
      params: this.state.parsing.failList,
      responseType: "blob"
    })
      .then((res) => {
        this.setState({ isLoading: false });
        // const fileName = res.headers["content-disposition"].split(";")[1].split("=")[1];
        const blob = new Blob([res as any]);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
          const a = document.createElement("a");
          // console.log(
          //   res.headers["content-disposition"].split(";")[1].split("=")[1],
          //   "asdsdasdasd",
          //   decodeURI(fileName)
          // );
          // a.download =
          //   decodeURI(fileName) ||
          //   (url.indexOf("/material") != -1 ? "物料错误数据.xlsx" : "工资错误数据.xlsx");
          a.download = "错误数据.xlsx";
          a.href = URL.createObjectURL(blob);
          document.querySelector("body")!.appendChild(a);
          a.click();
          document.querySelector("body")!.removeChild(a);
        };
        this.onCancel();
        this.restParsing();
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };
  //提示框
  openNotification = () => {
    // (notification as any).close(this.parsingKey);
    const key = `open${Date.now()}`;
    this.parsingKey = key;
    // const btn = (
    //     <Button
    //         type='primary'
    //         size='small'
    //         onClick={() => {
    //             this.downErroData()
    //             notification.close(key)
    //         }}>
    //         下载
    //     </Button>
    // );
    const messageTip = (
      <div>
        本次导入数据有
        <span>{this.state.parsing.failCount}条</span>
        错误数据请及时
        <span
          onClick={() => {
            this.downErroData();
            (notification as any).destroy(key);
          }}
          style={{ color: "#1890ff", cursor: "pointer" }}
        >
          下载
        </span>
      </div>
    );
    notification.open({
      message: messageTip,
      // btn,
      key,
      onClose: () => {
        this.restParsing();
      },
      duration: 180,
      className: "parsing_right_messge"
    });
  };
  // 下载模板
  download = () => {
    this.setState({ isLoading: true });
    request({
      url: `${this.state.params!.downTempUrl}`,
      responseType: "blob"
    })
      .then((res) => {
        this.setState({ isLoading: false });
        // const fileName = res.headers["content-disposition"].split(";")[1].split("=")[1];
        const blob = new Blob([res as any]);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          const a = document.createElement("a");
          a.download = `${this.state.params?.templateName ?? "数据模板"}.xlsx`;
          // a.download = fileName
          a.href = URL.createObjectURL(blob);
          document.querySelector("body")!.appendChild(a);
          a.click();
          document.querySelector("body")!.removeChild(a);
        };
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };
  // 重置解析数据
  restParsing = () => {
    this.setState({
      parsing: {
        // allCount: 0,//导入数据总条数
        failCount: 0, //导入数据错误条数
        isComplete: true, //是否有错误数据
        completeCount: 0 //导入正确数据
      }
    });
  };
  //取消
  onCancel = () => {
    // this.state.params.onCloseImportDataModal(false, 1);
    this.setState({
      InputFileList: [],
      selectedFileList: [],
      isShow: false
    });
    const files = document.getElementById("fileExcel") as HTMLInputElement;
    files.value = "";
  };
  render() {
    const { parsing } = this.state;
    // const { downTempUrl, title } = this.state.params!;
    return (
      <div id="Segment_xamine">
        {/* {this.state.isLoading ? <LoadingLG /> : null} */}
        <Modal
          visible={this.state.isShow}
          onCancel={this.onCancel}
          onOk={() => {
            this.uploadFile();
          }}
          okText="确定"
          cancelText="取消"
          centered
          title={this.state.params?.title ?? "导入数据"}
          className="LigandUpModal"
        >
          <Spin spinning={this.state.isLoading}>
            <div className="tips">
              <p>
                如果您没有批量文件模板，请点击下载模板
                <span
                  style={{ color: "#3FA8FF", cursor: "pointer" }}
                  onClick={() => {
                    this.download();
                    // Business.downBlobFile(`/sinzetech-order/jlorder/template`, "上传采购单.xlsx");
                  }}
                >
                  《{this.state.params?.templateName ?? "导入模版"}》
                </span>
              </p>
              <p>上传时请勿关闭网页</p>
              <p>支持扩展名：.xlsx,.xls,一次最多1000条</p>
            </div>
            <div className="files" hidden={!parsing.isComplete}>
              <div className="uploadUI">选择导入文件</div>
              <input
                id="fileExcel"
                type="file"
                name="files"
                onChange={this.fromChange}
                ref="file"
                multiple={this.state.params?.multiple}
                mozdirectory="true"
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
            <div className="selectedFile">
              {this.state.selectedFileList.map((item, index) => {
                return <p key={index}>{item.name}</p>;
              })}
              {/* <div
                            className='upload_line'
                            hidden={currentFileSize === fileSize ? true : false}
                        >
                            <div className='current_line' style={{ width: ((currentFileSize / fileSize) * 100) + '%' }}></div>
                        </div> */}
            </div>
            <div className="analysis_progress" hidden={parsing.isComplete}>
              {/* <div className='analysis_line' >
                            <div className='current_line' style={{ width: (((parsing.failCount + parsing.successCount) / parsing.allCount) * 100) + '%' }}></div>
                        </div> */}
              <div className="analysis_num">
                <span className="text">{parsing.isComplete ? "已完成" : "正在上传..."}</span>
                {/* <p>{parsing.failCount + parsing.successCount + '/' + parsing.allCount}</p> */}
                <p className="number">
                  错误条数：<span className="err">{parsing.failCount}</span>， 成功条数：
                  <span className="succ">{parsing.completeCount}</span>
                </p>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
export default ImportData;
